import React from "react"
import { Link } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-analytics"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO />
    <div>
      <ul className="about">
        <li>
          I lived in{" "}
          <OutboundLink href="//en.wikipedia.org/wiki/Malvern,_Worcestershire">
            England.
          </OutboundLink>
        </li>
        <li>
          I went to{" "}
          <OutboundLink href="//www.hull.ac.uk/">university</OutboundLink> in
          the north.
        </li>
        <li>
          I then moved to{" "}
          <OutboundLink href="//en.wikipedia.org/wiki/Capitol_Hill_(Seattle)">
            Seattle.
          </OutboundLink>
        </li>
        <li>
          I like watching sports, especially the{" "}
          <OutboundLink href="//www.seahawks.com/">Seahawks.</OutboundLink>
        </li>
        <li>
          I enjoy trying not to burn myself on my{" "}
          <OutboundLink href="//biggreenegg.com">Big Green Egg.</OutboundLink>
        </li>
        <li>
          I try my hand at <Link to="/art">drawing</Link> every now and then.
        </li>
        <li>
          I enjoy reading{" "}
          <OutboundLink href="//brandonsanderson.com/books/the-stormlight-archive/">
            fiction
          </OutboundLink>{" "}
          and{" "}
          <a href="//www.vertigocomics.com/characters/fables">
            graphic novels.
          </a>
        </li>
        <li>
          I like to{" "}
          <OutboundLink href="//github.com/pprice">code,</OutboundLink> I've
          been doing it for way too long.
        </li>
        <li>
          I worked on{" "}
          <OutboundLink href="//www.visualstudio.com">
            Visual Studio
          </OutboundLink>{" "}
          and{" "}
          <OutboundLink href="//www.microsoft.com/en-us/ai/default.aspx">
            AI
          </OutboundLink>{" "}
          at <OutboundLink href="//www.microsoft.com/">Microsoft.</OutboundLink>
        </li>
        <li>
          I now work at{" "}
          <OutboundLink href="//www.pinterest.com/">Pinterest</OutboundLink> in{" "}
          <OutboundLink href="//www.pinterestcareers.com/seattle">
            Seattle.
          </OutboundLink>
        </li>
      </ul>
    </div>
  </Layout>
)

export default IndexPage
